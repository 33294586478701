import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Header } from '../sidebars/sidebarElements/SidebarTemplate';
import Checkbox from '@components/form/toggle.component';

export const ToolButtonContainer = styled.div`
	position: relative;
`;
export const ToolButton = styled(Button)`
	z-index: 1;
	padding: 2px 4px;
	&.btn {
		background-color: ${props => props.theme.colors.modal};
		color: ${props => props.theme.colors.textColor};

		&:hover {
			background-color: ${props => props.theme.colors.textColor};
			color: ${props => props.theme.colors.background};
		}

		${props =>
			props.active &&
			`
			background-color: ${props.theme.colors.textColor};
			color: ${props.theme.colors.background};
		`}
	}
	svg {
		font-size: 1.25em;
	}
`;

export const OuterContainer = styled.div`
	position: absolute;
	bottom: 32px;
	left: 0;
	width: 280px;
	padding-bottom: 8px;
	z-index: 1;
`;

export const StyledCheckbox = styled(Checkbox)`
	margin-left: 20px;
	text-transform: capitalize;
`;

export const StyledHeader = styled(Header)`
	background-color: ${props => props.theme.colors.backgroundAccent};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: ${props => props.theme.colors.backgroundAccent};

	h2 {
		color: #fff;
		font-size: 1rem;
		opacity: 0.7;
		font-weight: 300;
		margin-bottom: 20px;
	}
`;


const SliderTool = ({
	tools,
	setTools,
	children,
	toolId,
	toolName,
	tooltipId,
	icon,
}) => {
	const handleToolClick = () => {
		setTools(
			tools.map(tool =>
				tool.name === toolId
					? { ...tool, isActive: !tool.isActive }
					: { ...tool, isActive: false }
			)
		);
	};

	return (
		<ToolButtonContainer>
			{children}
			<ToolButton
				onClick={handleToolClick}
				active={
					tools?.filter(tool => tool.name === toolId)[0]?.isActive
				}
				size="sm"
				data-tooltip-id={tooltipId}
				data-tooltip-content={
					tools?.filter(tool => tool.name === toolId)[0]?.isActive
						? null
						: toolName
				}
				data-tooltip-place="top">
				{icon}
			</ToolButton>
		</ToolButtonContainer>
	);
};

export default SliderTool;
