import { ProjectMode } from '@contexts/Project.context';
import Draw from 'ol/interaction/Draw';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

/**
 * Adds a freehand drawing interaction to the map
 * @param {string} type - the type of geometry to draw
 * @returns {Array} an array containing the draw interaction and the layer
 */
export const setupFreehandDraw = ({
	type = 'Polygon',
	mapRef,
	freehand = true,
}) => {
	if (!mapRef) {
		console.error('Map reference is required');
		return;
	}

	const source = new VectorSource({ wrapX: false });
	const layer = new VectorLayer({ source: source, zIndex: 22 });
	const draw = new Draw({
		source: source,
		type: type,
		freehand,
	});

	mapRef.addLayer(layer);
	mapRef.addInteraction(draw);

	return { draw, layer, source };
};

/**
 * 	* Removes the freehand draw interaction and layer from the map
 * @param {Object} mapRef - the map reference
 * @param {Object} draw - the draw interaction to remove
 * @param {Object} layer - the layer to remove
 */
export const removeFreehandDraw = ({ mapRef, draw, layer }) => {
	if (!mapRef) {
		console.error('Map reference is required');
		return;
	}

	if (draw) {
		mapRef.removeInteraction(draw);
	}
	if (layer) {
		mapRef.removeLayer(layer);
	}
};

/**
 * Draws an area of interest on the map and returns the coordinates
 * @returns {Promise} a promise that resolves to the coordinates of the area of interest
 * @example
 * drawAreaOfInterest().then((coordinates) => {
 *    console.log(coordinates);
 * });
 */
export const checkAreaOfInterest = ({ mode, grid, draw }) => {
	const squaresWithinPolygon = [];

	return new Promise((resolve, reject) => {
		if (!grid || grid.length === 0) {
			console.error('No squares to check');
			reject('Error: could not start training');
			return;
		}

		draw.on('drawend', function (e) {
			const results = e.feature.getGeometry().getCoordinates();

			if (mode === ProjectMode.ORTOPHOTO) {
				const polygonTooSmall = isPolygonSmallerThanSquare(
					grid[0].geometry.coordinates,
					results
				);

				if (polygonTooSmall) {
					reject(
						'The selected area is too small. please select a larger area.'
					);
					return;
				}
			}

			for (let i = 0; i < grid.length; i++) {
				const squareCoordinates = grid[i].geometry.coordinates;
				const isWithinPolygon = isSquareWithinPolygon(
					squareCoordinates,
					results
				);
				if (isWithinPolygon) {
					squaresWithinPolygon.push(grid[i]);
				}
			}

			if (squaresWithinPolygon.length === 0) {
				reject(
					'No photo to annotate in the selected area. Please select a different area.'
				);
				return;
			}

			if (
				mode === ProjectMode.SINGLE_IMAGE &&
				squaresWithinPolygon.length < 5
			) {
				reject(
					'The selected area is too small. Please select a larger area.'
				);
				return;
			}

			resolve(squaresWithinPolygon);
		});
	});
};

// an algorithm inside a single function to check if a exampleSquare is within examplePolygon
const isSquareWithinPolygon = (square, polygon) => {
	const squareCoordinates = square[0];
	const polygonCoordinates = polygon[0];
	const squareCenter = getSquareCenter(squareCoordinates);

	const squareCenterWithinPolygon = isPointWithinPolygon(
		squareCenter,
		polygonCoordinates
	);

	return squareCenterWithinPolygon;
};

// get the center of a square
export const getSquareCenter = squareCoordinates => {
	const squareCenter = [
		(squareCoordinates[0][0] + squareCoordinates[2][0]) / 2,
		(squareCoordinates[0][1] + squareCoordinates[2][1]) / 2,
	];
	return squareCenter;
};

// check if a point is within a polygon
const isPointWithinPolygon = (point, polygonCoordinates) => {
	const x = point[0];
	const y = point[1];
	let inside = false;
	for (
		let i = 0, j = polygonCoordinates.length - 1;
		i < polygonCoordinates.length;
		j = i++
	) {
		const xi = polygonCoordinates[i][0];
		const yi = polygonCoordinates[i][1];
		const xj = polygonCoordinates[j][0];
		const yj = polygonCoordinates[j][1];
		const intersect =
			yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}
	return inside;
};

// a function that checks if examplePolygon is smaller than the square, console log "Polygon is smaller than a square. draw a bigger area"
const isPolygonSmallerThanSquare = (square, polygon) => {
	const squareCoordinates = square[0];
	const polygonCoordinates = polygon[0];
	const squareArea = getSquareArea(squareCoordinates);
	const polygonArea = getPolygonArea(polygonCoordinates);
	if (Math.abs(polygonArea) > squareArea) {
		return false;
	} else {
		return true;
	}
};

// get the area of a square
const getSquareArea = squareCoordinates => {
	const squareArea = Math.abs(
		(squareCoordinates[0][0] - squareCoordinates[2][0]) *
			(squareCoordinates[0][1] - squareCoordinates[2][1])
	);
	return squareArea;
};

// get the area of a polygon
const getPolygonArea = polygonCoordinates => {
	let area = 0;
	for (let i = 0; i < polygonCoordinates.length; i++) {
		const j = (i + 1) % polygonCoordinates.length;
		area += polygonCoordinates[i][0] * polygonCoordinates[j][1];
		area -= polygonCoordinates[i][1] * polygonCoordinates[j][0];
	}
	area /= 2;
	return area;
};
