import { api } from './index.js';

const endpoint = '/subscriptions';

export const getUserSubscription = async subscription_id => {
	if (!subscription_id) {
		return new Error('No subscription id provided');
	}

	const { data } = await api.get(`${endpoint}/${subscription_id}`);
	return data;
};

export const getMonthlyProjectsLimit = async () => {
	const { data } = await api.get(`${endpoint}/monthly_projects_limit`);
	return data;
};

export const getMonthlyAITrainingLimit = async () => {
	const { data } = await api.get(`${endpoint}/monthly_ai_training_limit`);
	return data;
};

export const getMonthlyDetectionsLimit = async () => {
	const { data } = await api.get(`${endpoint}/monthly_detections_limit`);
	return data;
};

export const getMonthlyUserJobsInfo = async () => {
	const { data } = await api.get(`${endpoint}/monthly_user_jobs_info`);
	return data;
};
