import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { Feature } from 'ol';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import { getCenter, boundingExtent } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { unByKey } from 'ol/Observable';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { convertToRgba } from '@utils/map/helpers';
import Loader from '@components/common/Loader';

import { useProject } from '@contexts/Project.context';

const LoadingContainer = styled.div`
	display: ${props => (props.isLoading ? 'flex' : 'none')};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed; /* Ensure it is positioned relative to the viewport */
	top: 50%;
	left: 50%;
	padding: 30px 20px;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	z-index: 9999; /* Ensure it is on top of other elements */

	p {
		text-align: center;
		margin: 1rem 0 0;
		text-transform: none;
	}
`;

const imagePositionsToMapCoordinates = (imagePositionPolygon, imageExtent) => {
	const xLength = imageExtent[2] - imageExtent[0];
	const yLength = imageExtent[3] - imageExtent[1];

	return imagePositionPolygon.map(polygonPositions =>
		polygonPositions.map(position => {
			const xValue = xLength * position[0] + imageExtent[0];
			const yValue = yLength * (1 - position[1]) + imageExtent[1];

			return [xValue, yValue];
		})
	);
};

const setPolygonStyle = (feature, colorScheme) => {
	const color = convertToRgba(colorScheme[feature.get('classId')]?.color);
	feature.setStyle(
		new Style({
			stroke: new Stroke({
				color: color,
				width: 3,
			}),
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)',
			}),
			text: new Text({
				text: `${(feature.get('confidence') * 100).toFixed(1)}%`,
				font: '12px Calibri,sans-serif',
				fill: new Fill({
					color: '#ffffff',
				}),
				stroke: new Stroke({
					color: '#000000',
					width: 3,
				}),
				placement: 'line',
				textAlign: 'left',
				textBaseline: 'bottom',
				overflow: true,
			}),
		})
	);
};

const SelectedPhotoLayer = ({ singlePhotosLayer }) => {
	const {
		colorScheme,
		mapObject,
		defaultProjection,
		mapTooltip,
		selectedSingleImage,
		toolBarVisible,
		dispatch,
	} = useProject();

	const { tooltip, tooltipRef } = mapTooltip;

	const selectInteractionKey = useRef(null);
	const selectedActive = useRef(false);

	const imageLayer = useRef(null);
	const polygonLayer = useRef(null);

	const [loading, setLoading] = useState(false);

	const updateImage = selectedFeature => {
		// Remove any existing tooltip
		tooltip.setPosition(undefined);
		tooltipRef.style = '';
		imageLayer.current?.setOpacity(0);

		// Clear any existing detections
		polygonLayer.current?.setVisible(false);
		polygonLayer.current?.getSource()?.clear();

		// There was a click outside of an image, reset and return
		if (!selectedFeature && selectedActive.current) {
			imageLayer.current.setVisible(false);
			singlePhotosLayer.setVisible(true);
			selectedActive.current = false;

			dispatch({ type: 'setToolBarVisible', payload: true });

			if (loading) setLoading(false);
			return;
		}

		const imageUrl = selectedFeature?.values_.image;
		// If no image, return
		if (!imageUrl) return;

		if (toolBarVisible) {
			dispatch({ type: 'setToolBarVisible', payload: false });
		}

		// Ref to know if a feature is selected
		selectedActive.current = true;

		// Hide the single photos layer
		singlePhotosLayer.setVisible(false);

		// Get the extent of the geometry
		const polygonCoordinates = selectedFeature.values_.polygons;
		const selectedExtent = boundingExtent(polygonCoordinates);

		// Create the source for the image layer
		const source = new Static({
			url: imageUrl,
			visible: true,
			projection: defaultProjection,
			imageExtent: selectedExtent,
		});
		imageLayer.current.setSource(source);

		// Check if the selected image has detections
		const detections = selectedFeature.values_.detections;
		if (detections?.features?.length > 0) {
			// Get the source and clear it
			const polygonVectorSource = polygonLayer.current.getSource();
			polygonVectorSource.clear();

			// Add the detections to the source
			detections.features.forEach(detection => {
				const polygonPositionTranslatedToMapCoordinates =
					imagePositionsToMapCoordinates(
						detection.geometry.coordinates,
						selectedExtent
					);

				const polygon = new Polygon(
					polygonPositionTranslatedToMapCoordinates
				);
				const polygonFeature = new Feature({
					geometry: polygon,
					data: detection.properties,
					classId: detection.properties.classid,
					className: detection.properties.classname,
					confidence: detection.properties.confidence,
				});
				setPolygonStyle(polygonFeature, colorScheme);
				polygonVectorSource.addFeature(polygonFeature);
			});
		}

		// Function to handle image load events
		const handleImageLoad = event => {
			const { type } = event;
			if (type === 'imageloadstart') {
				setLoading(true);

				return;
			}

			if (type === 'imageloadend') {
				imageLayer.current.setOpacity(1);
				if (detections?.features?.length > 0) {
					polygonLayer.current.setVisible(true);
				}
			}
			setLoading(false);
		};

		// Attach event listeners to the source
		source.on('imageloadstart', handleImageLoad);
		source.on('imageloadend', handleImageLoad);
		source.on('imageloaderror', handleImageLoad);

		const view = mapObject.getView();
		const size = mapObject.getSize();

		// Zoom to the selected image
		const resolution = view.getResolutionForExtent(selectedExtent, size);
		const zoom = view.getZoomForResolution(resolution) - 0.5;
		view.animate({
			center: getCenter(selectedExtent),
			zoom: zoom,
			duration: 300,
		});

		imageLayer.current.setVisible(true);
	};

	const initializeLayers = useCallback(() => {
		console.log('updating selected photo layer');

		// Create the necessary layers if they don't exist
		if (!imageLayer.current) {
			imageLayer.current = new ImageLayer({
				source: null,
				zIndex: 21,
				visible: false,
				image: {
					rotateWithView: true,
				},
			});
			mapObject.addLayer(imageLayer.current);
		}

		if (!polygonLayer.current) {
			polygonLayer.current = new VectorLayer({
				source: new VectorSource(),
				zIndex: 24,
				visible: false,
				name: 'Detection results',
				properties: {
					customLayerId: 'polygonResultsLayer',
				},
			});
			mapObject.addLayer(polygonLayer.current);
		}

		// If the select interaction is not set, set it
		if (!selectInteractionKey.current) {
			console.log('init inertaction');

			const selectInteraction = new Select({
				layers: [singlePhotosLayer],
				condition: click,
			});
			mapObject.addInteraction(selectInteraction);

			const interactionKey = selectInteraction.on('select', e => {
				if (e.selected.length > 0) {
					// If a feature is already selected, return
					if (selectedActive.current) return;

					// A feature was clicked, show the image
					const selectedFeature = e.selected[0];
					dispatch({
						type: 'setSelectedSingleImage',
						payload: selectedFeature,
					});
				} else {
					dispatch({ type: 'setSelectedSingleImage', payload: null });
				}
			});

			selectInteractionKey.current = interactionKey;
		}
	}, [singlePhotosLayer]);

	const updatePolygonStyles = useCallback(() => {
		const polygonVectorSource = polygonLayer.current?.getSource();

		if (!polygonVectorSource || !colorScheme) return;
		polygonVectorSource.getFeatures()?.forEach(feature => {
			setPolygonStyle(feature, colorScheme);
		});
	});

	useEffect(() => {
		if (singlePhotosLayer) {
			initializeLayers();
		}
	}, [initializeLayers]);

	useEffect(() => {
		updatePolygonStyles(colorScheme);
	}, [colorScheme, polygonLayer.current]);

	useEffect(() => {
		updateImage(selectedSingleImage);
	}, [selectedSingleImage]);

	useEffect(() => {
		return () => {
			// Clean up on unmount

			console.log('cleaning up selected photo layer');

			// Reset all refs
			selectedActive.current = false;
			if (imageLayer.current) {
				mapObject.removeLayer(imageLayer.current);
				imageLayer.current = null;
			}
			if (polygonLayer.current) {
				mapObject.removeLayer(polygonLayer.current);
				polygonLayer.current = null;
			}
			if (singlePhotosLayer) {
				singlePhotosLayer.setVisible(true);
			}

			if (selectInteractionKey.current) {
				unByKey(selectInteractionKey.current);
				selectInteractionKey.current = null;
			}
		};
	}, []);

	return (
		<>
			{loading &&
				createPortal(
					<LoadingContainer isLoading={true}>
						<Loader inline />
						<p>
							Loading image.. <br />
							Load time depends on image size
						</p>
					</LoadingContainer>,
					document.body
				)}
		</>
	);
};
export default SelectedPhotoLayer;
