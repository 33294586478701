import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

import { getUserStorage, getMonthlyUserJobsInfo } from '@api';
import { useAuth } from '@contexts/User.context';

import StorageStatus from '@components/storage/StorageStatus';
import Loader from '@components/common/Loader';

const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	gap: 1rem;
	align-items: stretch;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

const CardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(${p => p.theme.colors.borderLightRgb}, 0.2);
`;

const List = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	line-height: 1.5em;
`;

const InfoHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 1rem;

	> div {
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	a {
		color: ${({ theme }) => theme.colors.textColor};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
		max-width: 100%;
	}
`;

const IncludedSubscriptionItems = () => {
	const { currentUser, subscription } = useAuth();

	const { data: monthlyUserJobsInfo } = useQuery({
		queryKey: ['monthlyUserJobsInfo', subscription?.id],
		queryFn: getMonthlyUserJobsInfo,
		enabled: !!subscription?.id,
	});

	const { monthly_projects, monthly_trainings, monthly_detections } =
		monthlyUserJobsInfo ?? {
			monthly_projects: null,
			monthly_trainings: null,
			monthly_detections: null,
		};

	if (!subscription) {
		return null;
	}

	const renderMonthlyInfo = (label, data, limit) => (
		<MonthlyInfo item={{ label, data, limit }} />
	);

	return (
		<Row>
			<CurrentStorageStatus
				currentUser={currentUser}
				subscription={subscription}
			/>

			{monthly_projects &&
				renderMonthlyInfo(
					'Projects',
					monthly_projects.info,
					monthly_projects.limit
				)}
			{monthly_trainings &&
				renderMonthlyInfo(
					'AI trainings',
					monthly_trainings.info,
					monthly_trainings.limit
				)}
			{monthly_detections &&
				renderMonthlyInfo(
					'Detections',
					monthly_detections.info,
					monthly_detections.limit
				)}
		</Row>
	);
};
export default IncludedSubscriptionItems;

const MonthlyInfo = ({ item: { label, data, limit } }) => {
	return (
		<Card className="border-0" bg="dark">
			<Card.Body>
				<CardHeader>
					<div>
						<strong>{label}</strong>
					</div>
					<div className="text-muted">
						Used{' '}
						<strong>
							{data?.length} out of {limit}
						</strong>
					</div>
				</CardHeader>

				{data?.length === 0 ? (
					<p className="text-muted mt-2 mb-0">
						No {label.toLowerCase()} used this period
					</p>
				) : (
					<List>
						{data?.map((monthlyItem, index) => (
							<Info item={monthlyItem} key={`monthly-${index}`} />
						))}
					</List>
				)}
			</Card.Body>
		</Card>
	);
};

const Info = ({
	item: {
		model_name,
		model_uuid,
		project_name,
		project_uuid,
		name,
		uuid,
		created_at,
		deleted,
	},
}) => {
	const title = model_name || name;
	const formattedDate = new Date(created_at).toLocaleDateString('nb-NO', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});

	const url = model_uuid
		? `/projects/${project_uuid}/?model=${model_uuid}`
		: `/projects/${uuid}`;

	return (
		<li>
			<InfoHeader>
				<div>
					<Link to={url}>{title}</Link>
					{deleted && <span className="text-muted"> (deleted)</span>}
				</div>

				<span className="text-muted small">{formattedDate}</span>
			</InfoHeader>
			{project_name && (
				<span className="text-muted small">{project_name}</span>
			)}
		</li>
	);
};

const CurrentStorageStatus = ({ currentUser, subscription }) => {
	// Get the user's used storage status
	const {
		data: storage,
		error: storageError,
		isLoading: userStorageLoading,
	} = useQuery({
		queryKey: ['userStorage', currentUser?.subscription_id],
		queryFn: getUserStorage,
		enabled: !!currentUser?.subscription_id,
	});

	if (userStorageLoading) {
		return <Loader message="Loading your storage status..." />;
	}

	if (storageError) {
		return (
			<Alert variant="danger" className="mt-4">
				Could not load your current file storage status. <br />
				Please contact{' '}
				<a href="mailto:post@biodrone.no">post@biodrone.no</a> if the
				problem persists.
			</Alert>
		);
	}

	const usedStorage = storage?.used ?? null;
	const totalStorage = subscription?.product_metadata?.storage_limit ?? null;

	return (
		<>
			{totalStorage &&
				usedStorage !== undefined &&
				usedStorage !== null && (
					<>
						<Card bg="dark" className="border-0">
							<Card.Body>
								<CardHeader>
									<strong>File storage status</strong>
								</CardHeader>
								<StorageStatus
									totalStorage={totalStorage}
									usedStorage={usedStorage}
								/>
							</Card.Body>
						</Card>
					</>
				)}
		</>
	);
};
