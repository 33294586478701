import { useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useProject } from '@contexts/Project.context';
import { getMasterFeatures } from '@utils/api';
import {
	makePolygonLayer,
	updatePolygonStyles,
} from '@utils/map/polygon.layer';
import Checkbox from '../sidebars/sidebarElements/checkbox.component';
import NoResult from '../sidebars/sidebarElements/noAnalyzes';
import { setupLayerDetails } from '@utils/map/helpers';

const PolygonLayer = ({ setExportData, setActiveSidebar, children }) => {
	const {
		mapObject,
		project,
		features,
		polygonLayer,
		colorScheme,
		colorOptions,
		pickedTask,
		dispatch,
	} = useProject();

	const taskId = pickedTask?.model_uuid;

	const {
		data: polygonData,
		isError,
		error,
	} = useQuery({
		queryKey: ['ploygon_master_features', project.uuid, taskId],
		queryFn: () => getMasterFeatures(project.uuid, taskId, 'polygon'),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const updatePolygonLayer = useCallback(() => {
		if (features?.length > 0 && mapObject) {
			const { modelClasses, colorStyles } = setupLayerDetails(
				features,
				colorOptions
			);

			dispatch({ type: 'setColorScheme', payload: colorStyles });
			dispatch({ type: 'setPickedModelClasses', payload: modelClasses });

			console.log('adding polygon layer');
			const layer = makePolygonLayer(mapObject, features, colorStyles);
			dispatch({ type: 'setPolygonLayer', payload: layer });
		} else {
			if (mapObject && polygonLayer) {
				dispatch({ type: 'setPickedModelClasses', payload: null });

				mapObject.removeLayer(polygonLayer);
				dispatch({ type: 'setPolygonLayer', payload: null });
			}
		}
	}, [features]);

	useEffect(() => {
		if (polygonData?.features) {
			dispatch({ type: 'setFeatures', payload: polygonData.features });
			setExportData(polygonData);
		}

		if (isError) {
			console.warn('could not fetch polygon', error);
			dispatch({ type: 'setFeatures', payload: [] });
			if (mapObject && polygonLayer) {
				mapObject.removeLayer(polygonLayer);
				dispatch({ type: 'setPolygonLayer', payload: null });
			}
		}
	}, [polygonData, isError]);

	useEffect(() => {
		updatePolygonLayer();
	}, [updatePolygonLayer]);

	useEffect(() => {
		updatePolygonStyles(colorScheme);
	}, [colorScheme]);

	if (!polygonLayer) return <NoResult />;

	return (
		<div id="polygonLayer">
			<Checkbox
				label={polygonLayer.get('name')}
				handleClick={setActiveSidebar}
				handleCheck={() => polygonLayer.setVisible(true)}
				handleUncheck={() => polygonLayer.setVisible(false)}
			/>
			{children && <ul>{children}</ul>}
		</div>
	);
};

export default PolygonLayer;
