import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { unByKey } from 'ol/Observable';

import Checkbox from '../sidebars/sidebarElements/checkbox.component';
import SelectedPhotoLayer from './SelectedPhotoLayer';

import { getUnrotatedGrid, fetchUrl, getMasterFeatures } from '@api';
import { useProject } from '@contexts/Project.context';
import { deleteLayerByCustomId, getLayerByCustomId } from '@utils/map/helpers';
import { createTooltip } from '@utils/map/tooltip.overlay';

const constructUrl = (projectUuid, path, imageName) => {
	return `filelink?key=${projectUuid}/${path}/${imageName}`;
};

const fetchImageWithFallback = async (compressedUrl, fullUrl) => {
	try {
		let image = await fetchUrl(compressedUrl);
		if (!image?.url) {
			throw new Error('No image found');
		}
		return image;
	} catch (error) {
		console.warn(
			'Error fetching compressed image, trying full image:',
			error
		);
		try {
			return await fetchUrl(fullUrl);
		} catch (error) {
			console.error('Error fetching full image:', error);
			return null;
		}
	}
};

const SinglePhotoLayer = ({ maxZoomLevel = 23 }) => {
	const {
		mapObject,
		defaultProjection,
		project,
		pickedTask,
		colorSchema,
		dispatch,
	} = useProject();

	const adding = useRef(false);
	const pointermovePopupKey = useRef(null);

	const tooltipRef = useRef(null);
	const tooltipContainerRef = useRef(null);
	const [tooltipContent, setTooltipConten] = useState(null);

	const [singlePhotosLayer, setSinglePhotosLayer] = useState(null);
	const singlePhotoLayerId = 'singlePhotosLayer';

	const {
		data,
		isError: dataLoadError,
		error,
	} = useQuery({
		queryKey: ['grid_tiles', project.uuid],
		queryFn: () => getUnrotatedGrid(project.uuid),
		enabled: !!project?.uuid && project?.image_mode === 'single_image',
		refetchOnWindowFocus: false,
		retry: false,
	});

	const { data: detectionsData } = useQuery({
		queryKey: [
			'single_photo_detections',
			project.uuid,
			pickedTask?.model_uuid,
		],
		queryFn: () =>
			getMasterFeatures(
				project.uuid,
				pickedTask.model_uuid,
				'normalized_predictions'
			),
		enabled: !!data?.features && !!pickedTask?.model_uuid,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const createTooltipContent = feature => {
		// Make mouse cursor a pointer
		mapObject.getTargetElement().style.cursor = 'pointer';

		const thumb = feature.get('thumbnail');
		if (!thumb) return;

		const coordinates = feature.getGeometry().getCoordinates();
		tooltipRef.current.setPosition(coordinates);

		const _rotation = feature.get('rotation');
		setTooltipConten({
			imageUrl: thumb,
			rotation: _rotation,
			detectionClasses: feature.get('detections')?.classes || [],
		});

		Object.assign(tooltipRef.current.style, {
			padding: '0',
			minWidth: '250px',
			minHeight: '150px',
			display: 'block',
			position: 'relative',
		});
	};

	const pointerMoveHandler = useCallback(
		event => {
			if (!mapObject) return;

			const feature = mapObject.forEachFeatureAtPixel(
				event.pixel,
				function (feature) {
					return feature;
				}
			);

			if (feature) {
				createTooltipContent(feature);
			} else {
				// Make mouse cursor a pointer
				mapObject.getTargetElement().style.cursor = 'default';
				tooltipRef.current.setPosition(undefined);
				tooltipRef.current.style = '';
			}
		},
		[tooltipRef, colorSchema]
	);

	const updateSinglePhotoLayer = useCallback(async () => {
		if (data?.features != null && !adding.current) {
			adding.current = true;

			const existingSinglePhotosLayer = getLayerByCustomId(
				mapObject,
				singlePhotoLayerId
			);

			if (existingSinglePhotosLayer) {
				setSinglePhotosLayer(existingSinglePhotosLayer);

				console.log(
					'Single photo layer already exists. Adding it to state.'
				);

				adding.current = false;
				return;
			}

			const singlePhotoSource = new VectorSource({
				features: [],
			});

			const layer = new VectorLayer({
				source: singlePhotoSource,
				visible: true,
				defaultProjection: defaultProjection,
				zIndex: 24,
				maxZoom: maxZoomLevel,
				properties: {
					customLayerId: singlePhotoLayerId,
				},
				style: feature => {
					const isDetect = !!feature.get('detections');
					return new Style({
						image: new CircleStyle({
							radius: 8,
							fill: new Fill({
								color: isDetect ? 'green' : 'yellow',
							}),
							stroke: new Stroke({ color: 'black', width: 1 }),
						}),
						zIndex: 14,
					});
				},
			});

			setSinglePhotosLayer(layer);

			mapObject.addLayer(layer);

			const featurePromises = data.features.map(
				async (feature, index) => {
					const {
						tile_name: imageName,
						center_longitude,
						center_latitude,
						gimbal_yaw_degrees,
						id,
					} = feature.properties;
					const { coordinates } = feature.geometry;

					let image, thumbnail;

					try {
						const compressedUrl = constructUrl(
							project.uuid,
							'images/compressed',
							imageName
						);
						const fullUrl = constructUrl(
							project.uuid,
							'images',
							imageName
						);
						const thumbnailUrl = constructUrl(
							project.uuid,
							'images/thumbnails',
							imageName
						);

						image = await fetchImageWithFallback(
							compressedUrl,
							fullUrl
						);
						thumbnail = await fetchUrl(thumbnailUrl);

						if (!thumbnail?.url || !image?.url) {
							throw new Error('Thumbnail or image URL not found');
						}
					} catch (e) {
						console.error('Could not get images from s3', e);
						return;
					}

					const _feature = new Feature({
						index: index,
						geometry: new Point(
							fromLonLat([center_longitude, center_latitude])
						),
						image: image.url,
						thumbnail: thumbnail.url,
						polygons: coordinates[0],
						name: imageName,
						rotation: gimbal_yaw_degrees,
						uuid: id,
						center: [center_longitude, center_latitude],
					});

					singlePhotoSource.addFeature(_feature);
				}
			);

			Promise.all(featurePromises).then(() => {
				const singleImageFeatures = singlePhotoSource.getFeatures();

				// If no features were added, console warn
				if (singleImageFeatures.length === 0) {
					//@TODO: Show a message to the user
					console.warn(
						'No features were added to the single photo layer'
					);
				} else {
					dispatch({
						type: 'setSingleImageFeatures',
						payload: singleImageFeatures,
					});
					dispatch({
						type: 'setFilteredImages',
						payload: singleImageFeatures,
					});
				}
			});

			adding.current = false;
		}
	}, [data]);

	useEffect(() => {
		updateSinglePhotoLayer();

		if (dataLoadError) {
			console.warn('could not fetch single photo', error);
			if (mapObject && singlePhotosLayer) {
				deleteLayerByCustomId(mapObject, singlePhotoLayerId);
				setSinglePhotosLayer(null);
			}
		}
	}, [updateSinglePhotoLayer, dataLoadError]);

	useEffect(() => {
		if (!singlePhotosLayer || tooltipRef.current) return;

		// Create Tooltip
		tooltipRef.current = createTooltip({
			mapRef: mapObject,
			tooltipRef: tooltipContainerRef.current,
		});

		// Add pointermove event listener
		const pointermoveKey = mapObject.on('pointermove', pointerMoveHandler);
		pointermovePopupKey.current = pointermoveKey;
	}, [singlePhotosLayer]);

	useEffect(() => {
		const singleImageFeatures = singlePhotosLayer
			?.getSource()
			?.getFeatures();

		// Remove any existing detections from the features
		singleImageFeatures?.forEach(feature => {
			feature.set('detections', null);
		});

		if (!detectionsData) return;

		if (detectionsData?.features) {
			// Iterate over each feature in singleImageFeatures
			singleImageFeatures.forEach(feature => {
				// Get the filename associated with the current feature
				const filename = feature.get('name');

				// Filter detections that match the current feature's filename
				const detections = detectionsData.features.filter(
					detection => detection.properties.filename === filename
				);

				// If there are any matching detections, process them
				if (detections.length > 0) {
					// Reduce detections to accumulate class information
					const classes = detections.reduce((acc, detection) => {
						const { classname: name, classid: id } =
							detection.properties;

						// Check if the class already exists in the accumulator
						const existingClass = acc.find(
							item => item.name === name && item.id === id
						);

						// If the class exists, increment its count
						if (existingClass) {
							existingClass.count += 1;
						} else {
							// If the class does not exist, add it to the accumulator
							acc.push({ name, id, count: 1 });
						}

						return acc;
					}, []);

					// Set the detections and classes information on the feature
					feature.set('detections', {
						features: detections,
						classes,
					});
				}
			});
		}
	}, [detectionsData]);

	useEffect(() => {
		return () => {
			// Clean up on unmount

			console.log('Cleaning up single photo layer');

			if (pointermovePopupKey.current) {
				unByKey(pointermovePopupKey.current);
				pointermovePopupKey.current = null;
			}

			deleteLayerByCustomId(mapObject, singlePhotoLayerId);
			setSinglePhotosLayer(null);

			if (mapObject && tooltipRef.current) {
				mapObject.removeOverlay(tooltipRef.current);
				tooltipRef.current = null;
			}
		};
	}, []);

	if (!singlePhotosLayer) return null;

	return (
		<div>
			<Checkbox
				label="Single Image"
				canEdit={false}
				defaultState={true}
				handleCheck={() => singlePhotosLayer.setVisible(true)}
				handleUncheck={() => singlePhotosLayer.setVisible(false)}
			/>
			<SelectedPhotoLayer singlePhotosLayer={singlePhotosLayer} />
			<div ref={tooltipContainerRef}>
				<TooltipComponent {...tooltipContent} />
			</div>
		</div>
	);
};

export default SinglePhotoLayer;

const StyledImage = styled.img`
	max-width: 250px;
	max-height: 250px;
	transform: rotate(${props => props.rotation}deg);
`;
const ClassItems = styled.div`
	padding: 5px 10px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 10px;
	font-size: 0.9rem;
	color: white;
	text-shadow: 1px 1px 1px black;
`;
const ColorDot = styled.span`
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 2px;
	background-color: rgba(
		${props => props.r},
		${props => props.g},
		${props => props.b},
		1
	);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
`;
const TooltipComponent = ({ imageUrl, rotation, detectionClasses }) => {
	if (!imageUrl || !rotation || !detectionClasses) return null;

	const { colorScheme } = useProject();
	const rotationDeg = rotation > 180 ? '0' : '180';

	return (
		<div>
			<StyledImage src={imageUrl} rotation={rotationDeg} />
			<ClassItems>
				{detectionClasses?.map(item => {
					const { r, g, b } = colorScheme[item.id]?.color || {
						r: 0,
						g: 0,
						b: 0,
						a: 0,
					};
					return (
						<div key={item.id}>
							<ColorDot r={r} g={g} b={b} />
							{item.count}
						</div>
					);
				})}
			</ClassItems>
		</div>
	);
};
