
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BiChevronDown } from 'react-icons/bi';


import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import Checkbox from '@components/form/toggle.component';
import Input from '@components/form/Input';

import UploadField from './UploadField';

const Actions = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	width: 100%;
	margin-top: 1em;
`;

const AdvancedWrapper = styled.div`
	border: 1px solid rgba(${p => p.theme.colors.borderLightRgb}, 0.2);
	padding: 1em 1em 0;
	border-radius: 0.375rem;
`;

const StyledChevron = styled(BiChevronDown)`
	transform: rotate(${p => (p.showadvanced === 'true' ? '-180deg' : '0deg')});
	transition: all 0.3s;
`;

export const FormInputs = ({
	submitting,
	files,
	setFiles,
	isOrthophoto,
	isOrthophotoProject,
	setIsOrthophoto,
	imageLimit,
}) => {

	const [showAdvanced, setShowAdvanced] = useState(false);

	return (
		<>
			<Input
				label="Project Name *"
				type="text"
				placeholder="Project Name"
				name="projectName"
				disabled={submitting}
				required
			/>

			<Input
				label="Project Description"
				type="text"
				name="projectDescription"
				placeholder="Project description"
				disabled={submitting}
			/>

			<UploadField
				files={files}
				setFiles={setFiles}
				isOrthophoto={isOrthophoto}
				isOrthophotoProject={isOrthophotoProject}
				setIsOrthophoto={setIsOrthophoto}
				disabled={submitting}
				imageLimit={imageLimit}
			/>

			{(!isOrthophoto && isOrthophotoProject) && (
				<>
					<div className="mt-3">
						<Button type="button" variant="link-light" onClick={() => { setShowAdvanced(!showAdvanced) }}>
							<span className="text-decoration-underline">Advanced settings <StyledChevron showadvanced={showAdvanced ? 'true' : 'false'} /></span>
						</Button>
					</div>

					{showAdvanced && (
						<AdvancedWrapper>
							<Checkbox
								defaultChecked={false}
								name="fastOrtophoto"
								id="new-project-fast-ortophoto"
								label="Fast Ortophoto"
								helperText="Faster project creation, but without height calculations"
							/>

							{/*
							Kept hidden for now
							<Checkbox
								defaultChecked={true}
								name="dsm"
								id="new-project-dsm"
								label="DSM"
							/>

							<Checkbox
								defaultChecked={false}
								id="new-project-ignore-gsd"
								label="Ignore GSD"
								name="ignoreGsd"
							/>

							<Checkbox
								defaultChecked={true}
								id="new-project-tiles"
								label="Tiles"
								name="tiles"
							/>

							<Input
								label="Resolution"
								step="0.1"
								min="0.01"
								max="10"
								tooltip="Ground resolution surface model and ortophoto"
								type="text"
								defaultValue="0.1"
								name="resolution"
							/>

							<Input
								label="Mesh Size"
								type="number"
								placeholder="Mesh Size"
								name="meshSize"
								defaultValue="200000"
							/> */}

						</AdvancedWrapper>
					)}
				</>
			)}
		</>
	);
};

export const FormActions = ({
	submitting,
	isDisabled
}) => {

	return (
		<Actions>
			<Button
				variant="secondary"
				as={Link}
				to="/projects"
				disabled={submitting}>
				Cancel
			</Button>

			<Button
				type="submit"
				variant="success"
				disabled={isDisabled()}>
				{submitting ? (
					<>
						Submitting ...{' '}
						<Spinner
							animation="border"
							variant="light"
							size="sm"
						/>
					</>
				) : (
					'Create project'
				)}
			</Button>
		</Actions>
	);
}
