import { humanFileSize } from '@utils/files';

import UpgradeStoragePlan from '@routes/project/newProject/parts/UpgradeStoragePlan';

import Alert from '@components/alert/Alert';
import StorageStatus from '@components/storage/StorageStatus';
import Loader from '@components/common/Loader';

import { useAuth } from '@contexts/User.context';

export default function Storage({
	projectFilesSize,
	userStorageQuery,
	isNotEnoughStorage,
}) {
	const { subscription, subscriptionLoading, subscriptionError } = useAuth();

	if (!userStorageQuery || !subscription) {
		return null;
	}

	const {
		data: storage,
		isLoading: userStorageIsLoading,
		error: userStorageError,
	} = userStorageQuery;

	if (userStorageIsLoading || subscriptionLoading) {
		return (
			<Alert variant="dark">
				<Loader inline message="Loading storage status..." />
			</Alert>
		);
	}

	if (userStorageError || subscriptionError) {
		console.error(userStorageError, subscriptionError);

		return (
			<p>
				<em>Storage status could not be loaded</em>
			</p>
		);
	}

	if (!subscription || subscription.status !== 'active') {
		return <p>You have no active subscription!</p>;
	}

	return (
		<Alert
			variant={isNotEnoughStorage ? 'danger' : 'dark'}
			heading="File Storage">
			{projectFilesSize > 0 && (
				<p className="mb-2">
					Estimated ~ {humanFileSize(projectFilesSize)} file storage
					for this project.
				</p>
			)}

			{isNotEnoughStorage ? (
				<p className="h5 my-3">
					You do not have enough storage left to start this project.
					Delete an existing project or upgrade your plan.
				</p>
			) : (
				<p className="small text-muted">
					Actual file storage will be calculated after the files are
					uploaded.
				</p>
			)}

			<div className="text-light">
				<StorageStatus
					projectFilesSize={projectFilesSize}
					usedStorage={storage?.used ?? null}
					totalStorage={
						subscription?.product_metadata?.storage_limit ?? 0
					}
				/>
			</div>

			<UpgradeStoragePlan />
		</Alert>
	);
}
