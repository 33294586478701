import { Route, Routes } from 'react-router-dom';

import { ProjectProvider } from '@contexts/Project.context';
import { ROLES, TIERS } from '@contexts/User.context';

import RequireAuth from '@components/RequireAuth';
import Root from '@components/view/Root';

import Home from '@routes/Home';
import Login from '@routes/Login';
import RegisterAccount from '@routes/RegisterAccount';
import RegisterTrialAccount from '@routes/RegisterTrialAccount';
import PrivacyPolicy from '@routes/PrivacyPolicy';
import BiodroneEULA from '@routes/BiodroneEULA';
import Contact from '@routes/Contact';
import Unauthorized from '@routes/Unauthorized';
import Missing404 from '@routes/Missing404';

import Checkout from '@routes/user/Checkout';
import Subscription from '@routes/user/Subscription';
import UserProjects from '@routes/user/Projects';
import Profile from '@routes/user/Profile';
import Subscribe from '@routes/user/Subscribe';
import VerifyUser from '@routes/user/Verify';
import PasswordReset from '@routes/user/ResetPassword';
import RequestPasswordReset from '@routes/user/RequestPasswordReset';

import MapEditor from '@routes/mapEditor/MapEditor';
import NewProject from '@routes/project/newProject/NewProject';
import EditProject from '@routes/project/EditProject';

import OrganizationProfile from '@routes/user/OrganizationProfile';
import OrganizationProjects from '@routes/user/OrganizationProjects';

import Users from './routes/admin/Users';
import Organizations from './routes/admin/Organizations';
import Organization from './routes/admin/Organization';
import AdminProjects from './routes/admin/Projects';
import AdminDemoProjects from './routes/admin/DemoProjects';



function App() {
	return (
		<Routes>
			<Route path="/" element={<Root />}>
				<Route index element={<Home />} />

				<Route path="login" element={<Login />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="eula" element={<BiodroneEULA />} />
				<Route path="contact" element={<Contact />} />
				<Route path="register" element={<RegisterAccount />} />
				<Route path="register-trial" element={<RegisterTrialAccount />} />
				<Route path="verify" element={<VerifyUser />} />
				<Route
					path="request-password-reset"
					element={<RequestPasswordReset />}
				/>
				<Route path="password-reset" element={<PasswordReset />} />

				<Route path="unauthorized" element={<Unauthorized />} />

				<Route
					element={
						<RequireAuth
							allowedRoles={[ROLES.Admin, ROLES.User]}
						/>
					}>
					<Route path="/projects">

						<Route
							index
							element={<UserProjects />}
						/>

						<Route
							path="new"
							element={<NewProject />}
						/>

						<Route
							path=":project_uuid"
							element={
								<ProjectProvider>
									<MapEditor />
								</ProjectProvider>
							}
						/>
						<Route
							path=":project_uuid/edit"
							element={<EditProject />}
						/>
					</Route>

					<Route path="/subscribe" element={<Subscribe />} />

					<Route path="/profile" element={<Profile />} />

					<Route path="/organization/:organization_uuid/profile" element={<OrganizationProfile />} />
					<Route path="/organization/:organization_uuid/projects" element={<OrganizationProjects/>} />

					<Route
					element={
						<RequireAuth
							allowedTiers={[TIERS.Pro]}
						/>
					}>
						<Route path="/checkout" element={<Checkout />} />

						<Route path="/subscription" element={<Subscription />} />

					</Route>

				</Route>

				<Route
					path="/admin"
					element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
					<Route path="users" element={<Users />} />
					<Route path="projects" element={<AdminProjects />} />
					<Route path="demo-projects" element={<AdminDemoProjects />} />
					<Route
						path="organizations/:organization_uuid"
						element={<Organization />}
					/>

					<Route
						path="organizations/create"
						element={<Organization />}
					/>

					<Route
						path="organizations"
						element={<Organizations />}
					/>
				</Route>

				{/* catch all */}
				<Route path="*" element={<Missing404 />} />
			</Route>
		</Routes>
	);
}

export default App;
