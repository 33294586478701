
import { useAuth } from "@contexts/User.context";

import AdminView from "@components/view/AdminView";
import Loader from '@components/common/Loader';
import Alert from "@components/alert/Alert";
import StripeProducts from "@components/products/StripeProducts";
import SubscriptionsAvailableForUser from "@components/subscription/SubscriptionsAvailableForUser";
import IncludedSubscriptionItems from "@components/subscription/IncludedSubscriptionItems";
import BillingPortal from "@components/subscription/BillingPortal";

import { recurringIntervalSwitch } from '@utils/helpers';

const StorageSubscription = () => {
	const {
		subscription,
		subscriptionLoading,
		subscriptionError,
	} = useAuth();

    return (
        <AdminView title="Subscription">
			{subscriptionLoading ? (
				<Loader message="Loading your subscription status..." />
			) : (
				<>
					{!subscription || subscription.status !== 'active' ? (
						<NoActiveSubscription />
					) : (
						<ActiveSubscription />
					)}
				</>
			)}

			{(subscriptionError) && (
				<Alert variant="danger">Could not get your supscription.</Alert>
			)}

        </AdminView>

    );
};
export default StorageSubscription;

const NoActiveSubscription = () => (
	<>
		<Alert variant="danger" className="w-50">You have no active subscription!</Alert>
		<h2 className="mt-5">Start a subscription</h2>
		<StripeProducts />
	</>
);

const ActiveSubscription = () => {

	const { subscription } = useAuth();

	// Billing
	const billingPeriodEnd = subscription?.current_period_end ? new Date(subscription.current_period_end * 1000) : null;
	const interval = subscription?.plan?.interval;
	const recurringInterval = recurringIntervalSwitch(interval);
	const price = subscription?.plan?.amount / 100;
	const currency = subscription?.currency;
	return(
		<>
			<h2 className="mb-4">Usage status for your active {recurringInterval} subscription:</h2>

			<IncludedSubscriptionItems />

			{billingPeriodEnd && (
				<p className="mt-4 small text-muted">
					Price per {interval}: { price ? Intl.NumberFormat('en-IN', {
						style: 'currency',
						currency: currency,
					}).format(price) : ' could not be loaded' }.<br/>
					Current billing period ends at {billingPeriodEnd.toLocaleDateString()}.
				</p>
			)}

			<BillingPortal />

			<div>
				<h2 className="mt-5 mb-4 h1">Change your subscription</h2>
				<SubscriptionsAvailableForUser />
			</div>

		</>
	);
};
