import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import styled, { css } from 'styled-components';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { VscRecord } from 'react-icons/vsc';
import { BiShapePolygon } from 'react-icons/bi';
import { GrMultiple } from 'react-icons/gr';
import { AiOutlineSearch } from 'react-icons/ai';

import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';
import { useProject, ProjectMode } from '@contexts/Project.context';

import Input from '@components/form/Input';
import Alert from '@components/alert/Alert';
import ModelList from './ModelList';

import { deleteMultiModel, deleteModel } from '@api';

const SearchInput = styled.div`
	position: sticky;
	left: 0;
	bottom: 0;
	margin-top: 10px;
	> div {
		margin: 0;
	}
	input {
		padding-left: 40px;
		border-top: 1px solid ${props => props.theme.colors.backgroundGrey};
	}
	svg {
		position: absolute;
		left: 8px;
		bottom: 4px;
	}
`;

const commonIconStyles = css`
	transform: scale(0.9);
	opacity: 0.5;
	margin-right: 8px;
	margin-left: -6px;
	path {
		stroke: currentColor;
	}
`;

const SegmentationIcon = styled(BiShapePolygon)`
	${commonIconStyles}
	margin-top: -4px;
`;
const ObjectDetectionIcon = styled(VscRecord)`
	${commonIconStyles}
`;
const MultiModelIcon = styled(GrMultiple)`
	${commonIconStyles}
`;

const AnalyzedTag = styled.span`
	background-color: ${props => props.theme.colors.green};
	color: white;
	padding: 2px 5px;
	border-radius: 5px;
	font-size: 0.8rem;
	margin-left: 10px;
	font-style: normal;
`;

const StyledTabs = styled(Tabs)`
	.nav-link {
		color: ${props => props.theme.colors.textColor};
		opacity: 0.8;
		transition: all 0.2s;
		&:hover {
			opacity: 1;
		}

		&.active {
			background: none;
			opacity: 1;
			border-bottom-color: ${props => props.theme.colors.background};
		}
	}
`;

const Models = ({
	pickTask,
	refetchTasks,
	modelsDetectedOn,
	refetchModelsDetectedOn,
}) => {
	const { addToast } = useToast();
	const { tierTrial } = useAuth();
	const { tasks, projectMode, dispatch } = useProject();

	const orthoModels = tasks?.filter(task => task.image_mode === 'orthophoto');
	const singleImageModels = tasks?.filter(
		task => task.image_mode === 'single_image'
	);

	const [searchValue, setSearchValue] = useState('');
	const handleSearch = e => {
		e.preventDefault();
		setSearchValue(e.target.value);
	};

	const deleteMutation = useMutation({
		mutationFn: task => {
			if (task.multimodel) {
				return deleteMultiModel(task);
			}

			return deleteModel(task.model_uuid);
		},
		onSuccess: () => {
			refetchTasks();
			refetchModelsDetectedOn();
			addToast({
				id: `model-delete-${new Date().getTime()}`,
				title: 'Model deleted successfully!',
				bg: 'success',
			});
		},
		onError: (error, task) => {
			console.error(error);
			const errorId = task.multimodel ? task.model_uuid : task.task_uuid;
			addToast({
				id: `model-delete-error-${errorId}`,
				bg: 'danger',
				title: 'Error: model could not be deleted!',
				message: error.response?.data?.detail || null,
			});
		},
	});

	const trashTask = async task => {
		if (tierTrial) {
			dispatch({
				type: 'setConfirmModalContent',
				payload: {
					title: 'Delete Model',
					message: `You cannot delete a model as a trial user`,
					confirmLabel: 'Ok',
					onConfirm: () =>
						dispatch({
							type: 'setConfirmModalContent',
							payload: null,
						}),
				},
			});
			return;
		}

		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: 'Delete Model',
				message: `Are you sure you want to delete ${task.description}?`,
				onConfirm: () => deleteMutation.mutate(task),
				onCancel: () =>
					dispatch({ type: 'setConfirmModalContent', payload: null }),
			},
		});
	};

	const taskTypeIcon = ({ multimodel, task_type }) => {
		if (multimodel) {
			return (
				<TooltipSpan content="Multi model">
					<MultiModelIcon />
				</TooltipSpan>
			);
		}

		if (task_type === 'object_detection') {
			return (
				<TooltipSpan content="Object detection model">
					<ObjectDetectionIcon />
				</TooltipSpan>
			);
		}

		return (
			<TooltipSpan content="Segmentation model">
				<SegmentationIcon />
			</TooltipSpan>
		);
	};

	const taskDescription = task => {
		return (
			<>
				{taskTypeIcon(task)}
				{task.description}
				{
					// If task.model_uuid is in modelsDetectedOn, show a small icon to indicate that the model contains results
					modelsDetectedOn &&
						modelsDetectedOn.includes(task.model_uuid) && (
							<AnalyzedTag>Analyzed</AnalyzedTag>
						)
				}
			</>
		);
	};

	return (
		<>
			<StyledTabs
				defaultActiveKey={projectMode}
				id="model-tabs"
				className="mb-3">
				<Tab
					eventKey={ProjectMode.ORTHOPHOTO}
					title="Orthophoto Models">
					{projectMode !== ProjectMode.ORTHOPHOTO && (
						<Alert variant="warning" className="py-2 mx-2">
							<p className="small">
								Only available for orthophoto projects
							</p>
						</Alert>
					)}
					<ModelList
						models={orthoModels}
						taskDescription={taskDescription}
						pickTask={pickTask}
						searchValue={searchValue}
						trashTask={trashTask}
						disabled={projectMode !== ProjectMode.ORTHOPHOTO}
					/>
				</Tab>
				<Tab
					eventKey={ProjectMode.SINGLE_IMAGE}
					title="Single Image Models">
					{projectMode !== ProjectMode.SINGLE_IMAGE && (
						<Alert variant="warning" className="py-2 mx-2">
							<p className="small">
								Only available for single image projects
							</p>
						</Alert>
					)}
					<ModelList
						models={singleImageModels}
						taskDescription={taskDescription}
						pickTask={pickTask}
						searchValue={searchValue}
						trashTask={trashTask}
						disabled={projectMode !== ProjectMode.SINGLE_IMAGE}
					/>
				</Tab>
			</StyledTabs>

			<SearchInput>
				<Input
					type="search"
					placeholder="Search"
					value={searchValue}
					onChange={handleSearch}
					autoFocus
				/>
				<AiOutlineSearch className="m-1 mb-2" />
			</SearchInput>
		</>
	);
};
export default Models;

const TooltipSpan = ({ content, children }) => (
	<span
		data-tooltip-id="tooltip-toolbar-root"
		data-tooltip-content={content}
		data-tooltip-place="left">
		{children}
	</span>
);
