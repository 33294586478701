import { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import { CloseIcon } from '../sidebars/sidebarElements/SidebarTemplate';

import { useProject } from '@contexts/Project.context';
import {
	Container,
	OuterContainer,
	StyledCheckbox,
	StyledHeader,
} from './SliderTool';

const NO_DETECTIONS = {
	id: 999,
	label: 'no detections',
	selected: true,
};

const FilterSinglePhotos = ({ tools, setTools }) => {
	const { singleImageFeatures, pickedModelClasses, dispatch } = useProject();

	const [filterOptions, setFilterOptions] = useState([]);

	const closeFilter = () =>
		setTools(tools.map(tool => ({ ...tool, isActive: false })));

	const filterSingleImageFeatures = event => {
		const filterOptionClicked = event.target.value;

		const filterOptionsChanged = filterOptions.map(option => {
			option.label === filterOptionClicked &&
				(option.selected = !option.selected);
			return option;
		});

		setFilterOptions(filterOptionsChanged);

		const labelsToShow = filterOptionsChanged
			.filter(option => option.selected)
			.map(option => option.label);

		const newFilteredImages = singleImageFeatures.filter(feature => {
			if (!feature.get('detections')) {
				return labelsToShow.includes(NO_DETECTIONS.label)
					? true
					: false;
			}

			return feature
				.get('detections')
				?.classes.some(classModel =>
					labelsToShow.includes(classModel.name)
				);
		});
		dispatch({
			type: 'setFilteredImages',
			payload: newFilteredImages,
		});
	};

	useEffect(() => {
		if (pickedModelClasses?.length > 0) {
			let modelClasses = [];
			pickedModelClasses?.forEach(model => {
				model.selected = true;
				modelClasses = [...modelClasses, model];
			});
			modelClasses = [...modelClasses, NO_DETECTIONS];
			setFilterOptions(modelClasses);
		}
	}, [pickedModelClasses]);

	if (!tools.filter(tool => tool.name === 'filtering')[0]?.isActive) {
		return null;
	}

	return (
		<OuterContainer>
			<Container>
				<StyledHeader>
					<h2>Filter Images</h2>
					<CloseIcon onClick={closeFilter} role="button" />
				</StyledHeader>
				<Form>
					{filterOptions?.map((modelClass, index) => (
						<StyledCheckbox
							key={modelClass.id}
							defaultChecked={modelClass.selected}
							label={modelClass.label}
							value={modelClass.label}
							onChange={filterSingleImageFeatures}
							id={`filter-type-${index}`}
						/>
					))}
				</Form>
			</Container>
		</OuterContainer>
	);
};

export default FilterSinglePhotos;
