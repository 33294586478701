const LimitInfo = ({
	limit,
	used,
	singularName, // e.g. 'project'
	pluralName, // e.g. 'projects'
	actionPastTense, // e.g. 'created'
	actionPresentTense, // e.g. 'creating'
	...props
}) => {
	const possibleLeft = limit - used - 1;

	return (
		<div {...props}>
			<p className="small text-muted">
				You have previously {actionPastTense}{' '}
				<strong>
					{used} out of {limit}
				</strong>{' '}
				{pluralName} this billing period.
				<br />
				{actionPresentTense} this {singularName} will leave you with{' '}
				<strong>
					{possibleLeft}{' '}
					{possibleLeft === 1 ? singularName : pluralName}
				</strong>{' '}
				remaining.
			</p>
		</div>
	);
};
export default LimitInfo;
