import { api } from './index.js';

export const getProducts = async req_data => {
	// lookup_keys is an array of product lookup keys to fetch from Stripe
	// Lookup keys are set in the Stripe dashboard when creating products
	const { lookup_keys, user_id } = req_data;
	const { data } = await api.post('/stripe/products', {
		lookup_keys: lookup_keys.join(','),
		user_id,
	});

	return data;
};

export const initiateTransaction = async ({
	price_id,
	mode = 'payment',
	return_path,
}) => {
	const { data } = await api.post(`/stripe/initiate`, {
		origin: window.location.origin,
		price_id,
		mode,
		return_path,
	});

	return data;
};

export const getBillingPortalUrl = async (request_data = {}) => {
	const {
		return_path,
		subscription_lookup_keys,
		show_default_subscriptions,
	} = request_data;

	const { data } = await api.post('/stripe/billing_portal', {
		origin: window.location.origin,
		return_path: return_path || window.location.pathname || '',
		subscription_lookup_keys,
		show_default_subscriptions,
	});
	return data;
};
