import styled from 'styled-components';
import { Fill, Stroke, Style } from 'ol/style';
import { Select } from 'ol/interaction';

import { useProject } from '@contexts/Project.context';
import HexagonAccordion from './sidebarElements/HexagonAccordion';
import {
	Header,
	HeaderText,
	Container,
	CloseIcon,
} from './sidebarElements/SidebarTemplate';

const Content = styled.div`
	background-color: ${props => props.theme.colors.background};
`;

const HexagonInfo = () => {
	const { selectedHexagon, selectedSingleImage, dispatch } = useProject();

	const closeHexagonInfo = () => {
		selectedHexagon.selectInteraction?.getFeatures().clear();
		dispatch({ type: 'setSelectedHexagon', payload: null });
	};

	if (!selectedHexagon || selectedSingleImage) return null;

	return (
		<Container align="right" zindex="20">
			<Content>
				<Header>
					<HeaderText>Selected Hexagon Info</HeaderText>
					<CloseIcon onClick={closeHexagonInfo} role="button" />
				</Header>
				<HexagonAccordion hexagonData={selectedHexagon.data} />
			</Content>
		</Container>
	);
};

export default HexagonInfo;

export function addHexagonInteraction(
	layer,
	mapObject,
	dispatch,
	hexagonInteractionAdded
) {
	const hexagonSelectStyle = new Style({
		zIndex: 1000,
		stroke: new Stroke({
			color: 'rgba(255, 140, 0, 1)',
			width: 4,
		}),
		fill: new Fill({
			color: 'rgba(255, 140, 0, 0.25)',
		}),
	});

	hexagonInteractionAdded.current = new Select({
		toggleCondition: () => false, // stops shift-hold from multi-selecting
		style: hexagonSelectStyle,
		layers: [layer],
	});

	hexagonInteractionAdded.current.on('select', function (e) {
		const { data } = e.selected[0]?.getProperties() ?? { data: null };
		if (data) {
			dispatch({
				type: 'setSelectedHexagon',
				payload: {
					data,
					selectInteraction: hexagonInteractionAdded.current,
				},
			});
		} else {
			dispatch({ type: 'setSelectedHexagon', payload: null });
		}
	});

	mapObject.addInteraction(hexagonInteractionAdded.current);

	layer.on('change:visible', () => {
		dispatch({ type: 'setSelectedHexagon', payload: null });
	});
}
