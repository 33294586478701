import { Translate } from 'ol/interaction';
import { primaryAction } from 'ol/events/condition';

export const setTranslateInteraction = ({ layer, updateModifiedObjects }) => {
	const interaction = new Translate({
		condition: function (event) {
			return primaryAction(event);
		},
		layers: [layer, updateModifiedObjects],
	});

	interaction.on('translatestart', event => {
		const currentFeature = event.features.item(0);
		const isInside = currentFeature.get('isInside');

		// Store the original coordinates
		const geometry = currentFeature.getGeometry();
		let originalCoordinates;

		if (geometry.getType() === 'Circle') {
			originalCoordinates = geometry.getCenter();
		} else {
			originalCoordinates = geometry.getCoordinates();
		}

		currentFeature.set('originalCoordinates', originalCoordinates, true);

		if (!isInside) {
			console.log('translatestart: not inside');
			event.preventDefault(); // Prevent the translation from happening
			return;
		}
	});

	interaction.on('translating', event => {
		const currentFeature = event.features.item(0);
		const isInside = currentFeature.get('isInside');

		if (!isInside) {
			event.preventDefault(); // Prevent the translation from happening

			// Revert to the original coordinates
			revertToOriginalCoordinates(currentFeature);
		}
	});

	interaction.on('translateend', event => {
		const currentFeature = event.features.item(0);
		const isInside = currentFeature.get('isInside');

		if (!isInside) {
			console.log('translateend: not inside');
			// Revert to the original coordinates
			revertToOriginalCoordinates(currentFeature);
		} else {
			// Update the modified objects
			updateModifiedObjects(currentFeature);
		}

		currentFeature.unset('originalCoordinates', true);
	});

	return interaction;
};

const revertToOriginalCoordinates = currentFeature => {
	const originalCoordinates = currentFeature.get('originalCoordinates');
	const geometry = currentFeature.getGeometry();
	if (geometry.getType() === 'Circle') {
		geometry.setCenter(originalCoordinates);
	} else {
		geometry.setCoordinates(originalCoordinates);
	}
};
