import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Draw, { createBox } from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { setTranslateInteraction } from './annotation/translate.interaction';
import { setModifyInteraction } from './annotation/modify.interaction';
import {
	activeSquareRef,
	annotationClassId,
	isFeatureIntersectingSquare,
	annotationStyles,
} from './annotation/annotation.interaction';
import { getSquareCenter } from './freeHand.draw';

const defaultDrawTool = 'Box';

let objectInteraction,
	objectLayer,
	snapInteraction,
	modifyInteraction,
	translateInteraction,
	activeDrawTool = defaultDrawTool;
let annotationClassIds = [];
let colorOptions = null;

const objectSource = new VectorSource();

let addedObjects = [];
let modifiedObjects = [];

export const startObjectAnnotation = ({
	colorOptionsInput,
	existingObjects,
	squareList,
	mapReference,
}) => {
	colorOptions = colorOptionsInput;
	objectLayer = new VectorLayer({
		source: objectSource,
		zIndex: 25,
		style: feature => annotationStyles(feature, colorOptions),
	});
	loadObjects(existingObjects, squareList);

	snapInteraction = new Snap({
		source: objectSource,
	});

	mapReference.addLayer(objectLayer);
	mapReference.addInteraction(snapInteraction);

	translateInteraction = setTranslateInteraction({
		layer: objectLayer,
		updateModifiedObjects,
	});
	mapReference.addInteraction(translateInteraction);

	modifyInteraction = setModifyInteraction({
		source: objectSource,
		updateModifiedObjects,
	});
	mapReference.addInteraction(modifyInteraction);

	setDrawInteraction({
		mapReference,
		drawTool: 'Box',
	});
};

export const setDrawInteraction = ({ mapReference, drawTool = null }) => {
	if (objectInteraction) {
		mapReference.removeInteraction(objectInteraction);
	}

	if (drawTool) {
		activeDrawTool = drawTool;
	}

	objectInteraction = new Draw({
		source: objectSource,
		type: 'Circle',
		geometryFunction: activeDrawTool === 'Box' ? createBox() : undefined,
	});
	mapReference.addInteraction(objectInteraction);

	objectInteraction.on('drawstart', function (e) {
		const currentFeature = e.feature;

		let isInside = isFeatureIntersectingSquare(
			currentFeature,
			activeSquareRef
		);

		if (isInside) {
			const currentSquareName = activeSquareRef?.properties.tile_name;
			const addedObject = e.feature;
			addedObject.values_.classId = annotationClassId;
			addedObject.fileName = currentSquareName;

			annotationClassIds.push(annotationClassId);
			snapInteraction.setActive(true);
			modifyInteraction.setActive(true);
		} else {
			objectInteraction.finishDrawing();
			snapInteraction.setActive(false);
			modifyInteraction.setActive(false);

			// moves view to the square that the user is trying to draw in
			mapReference.getView().animate({
				center: getSquareCenter(
					activeSquareRef.geometry.coordinates[0]
				),
				zoom: 21.5,
				duration: 300,
			});
		}
	});

	objectInteraction.on('drawend', function (e) {
		const currentSquareName = activeSquareRef.properties.tile_name;
		const addedObject = e.feature;

		if (addedObjects[currentSquareName] === undefined) {
			addedObjects[currentSquareName] = [];
		}
		addedObjects[currentSquareName].push(addedObject);
	});
};

// helper function that enables user to delete a object on click
const deleteObject = feature => {
	const currentSquareName = activeSquareRef.properties.tile_name;

	if (addedObjects[currentSquareName] !== undefined) {
		addedObjects[currentSquareName].forEach((circle, index) => {
			if (circle.ol_uid === feature.ol_uid) {
				console.log('deleted from addedObjects');
				addedObjects[currentSquareName].splice(index, 1);
				objectSource.removeFeature(feature);
				return;
			}
		});
	}

	if (modifiedObjects[currentSquareName] !== undefined) {
		modifiedObjects[currentSquareName].forEach((circle, index) => {
			if (circle.values_.id === feature.values_.id) {
				console.log('deleted from modified circles');
				modifiedObjects[currentSquareName].splice(index, 1);
				objectSource.removeFeature(feature);
				return;
			}
		});
	}
};

export const getObjectEraseData = () => {
	return {
		interactionsToDeactivate: [
			objectInteraction,
			snapInteraction,
			modifyInteraction,
			translateInteraction,
		],
		selectedLayer: objectLayer,
		deleteFunction: deleteObject,
	};
};

export const getObjectsInSquare = (square, taskId) => {
	const allPreparedObjects = prepareObjectData(taskId);
	const objectsInSquare = [];
	let hasOverlappedAnnotations = false; // Only need to check this if there are no objects in the square

	allPreparedObjects.forEach(feature => {
		if (feature.tile_name === square.properties.tile_name) {
			objectsInSquare.push(feature);
		}
	});

	// No objects in square, checking for overlapped annotations
	if (objectsInSquare.length === 0) {
		allPreparedObjects.some(feature => {
			hasOverlappedAnnotations = isFeatureIntersectingSquare(
				feature,
				square
			);
			return hasOverlappedAnnotations; // This will stop the loop if hasOverlappedAnnotations is true
		});
	}

	return { annotations: objectsInSquare, hasOverlappedAnnotations };
};

/**
 * Stops drawing objects and returns the objects array
 * @returns {Array} an array containing the objects
 */
export const stopDrawingObjects = mapReference => {
	objectLayer.getSource().clear();
	mapReference.removeLayer(objectLayer);
	mapReference.removeInteraction(objectInteraction);
	mapReference.removeInteraction(snapInteraction);
	mapReference.removeInteraction(modifyInteraction);
	mapReference.removeInteraction(translateInteraction);

	// zooms out to the default zoom level
	mapReference.getView().animate({
		zoom: 17,
		duration: 300,
	});

	const results = prepareObjectData();

	//Set all variables to default
	objectInteraction = null;
	objectLayer = null;
	snapInteraction = null;
	modifyInteraction = null;
	translateInteraction = null;
	activeDrawTool = defaultDrawTool;
	annotationClassIds = [];
	colorOptions = null;
	addedObjects = [];
	modifiedObjects = [];

	return results;
};

/**
 * loads the objects from the database
 * @param {Array} objects - an array of objects
 * @returns {void}
 * @todo add correct file name to the objects
 */
const loadObjects = (objects, squareList) => {
	let objectsFeatures = [];

	objects?.forEach(feature => {
		const x = feature.geometry.coordinates[0];
		const y = feature.geometry.coordinates[1];
		const radius = feature.properties.radius;
		const halfSize = radius; // Assuming radius is half the side length of the box
		const classId = feature.properties.classid;

		const objectFeature = new Feature({
			id: feature.properties.annotation_uuid,
			classId: classId,
			geometry: new Polygon([
				[
					[x - halfSize, y - halfSize],
					[x + halfSize, y - halfSize],
					[x + halfSize, y + halfSize],
					[x - halfSize, y + halfSize],
					[x - halfSize, y - halfSize],
				],
			]),
			radius: radius,
			center: [x, y],
		});

		objectsFeatures.push(objectFeature);
		objectSource.addFeature(objectFeature);
		annotationClassIds.push(classId);
	});

	squareList?.forEach(square => {
		const squareName = square.properties.tile_name;
		if (modifiedObjects[squareName] === undefined) {
			modifiedObjects[squareName] = [];
		}
		objectsFeatures.forEach(feature => {
			const isInside = isFeatureIntersectingSquare(feature, square);
			if (isInside) {
				feature.values_.squareName = squareName;
				modifiedObjects[squareName].push(feature);
			}
		});
	});
	return objectsFeatures;
};

const prepareObjectData = taskId => {
	let preparedAddedObjects = [];
	let preparedModifiedObjects = [];

	function extractFeatureData(feature, squareName) {
		const modelType = feature.getGeometry().getType().toLowerCase();
		let featureData = {
			classId: feature.values_.classId,
			tile_name: squareName,
		};

		if (modelType === 'circle') {
			featureData.radius = feature.getGeometry().getRadius();
			featureData.center = feature.getGeometry().getCenter();
			featureData.annotation_type = 'circle';
		} else if (modelType === 'polygon') {
			featureData.coordinates = feature.getGeometry().getCoordinates()[0];
			featureData.annotation_type = 'bbox';
		}

		return featureData;
	}

	if (Object.keys(addedObjects).length > 0) {
		for (const squareName in addedObjects) {
			addedObjects[squareName].forEach(feature => {
				const featureData = extractFeatureData(feature, squareName);
				preparedAddedObjects.push(featureData);
			});
		}
	}

	if (Object.keys(modifiedObjects).length > 0) {
		for (const squareName in modifiedObjects) {
			modifiedObjects[squareName].forEach(feature => {
				const featureData = extractFeatureData(feature, squareName);
				featureData.id = feature.values_.id; // Add the id for modified objects
				preparedModifiedObjects.push(featureData);
			});
		}
	}

	const results = {
		new: preparedAddedObjects,
		modified: preparedModifiedObjects,
	};

	let postData = preparePostData(results, taskId);

	return postData;
};

const createAnnotation = (annotation, taskId, isModified = false) => {
	const modelType = annotation.annotation_type.toLowerCase();
	const square = annotation.tile_name;
	const classId = annotation.classId;

	let baseAnnotation = {
		uuid: isModified ? annotation.id : 'unknown',
		task_uuid: taskId,
		annotation_type: modelType,
		machine_annotation: isModified,
		deprecated: false,
		human_input: true,
		classid: classId,
		tile_name: square,
	};

	if (modelType === 'circle') {
		const radius = annotation.radius;
		const center = annotation.center;
		const x = center[0];
		const y = center[1];
		return {
			...baseAnnotation,
			x_center: x,
			y_center: y,
			radius: radius,
		};
	} else if (modelType === 'bbox') {
		const coordinates = annotation.coordinates;
		return {
			...baseAnnotation,
			coordinates: coordinates,
		};
	}

	return baseAnnotation;
};

const preparePostData = (data, taskId) => {
	let postData = [];

	if (data.new && data.new.length > 0) {
		for (let i = 0; i < data.new.length; i++) {
			const newAnnotation = createAnnotation(data.new[i], taskId);
			postData.push(newAnnotation);
		}
	}

	if (data.deleted && data.deleted.length > 0) {
		for (let i = 0; i < data.deleted.length; i++) {
			console.log('deleted: ', data.deleted[i]);
		}
	}

	if (data.modified && data.modified.length > 0) {
		for (let i = 0; i < data.modified.length; i++) {
			const modifiedAnnotation = createAnnotation(
				data.modified[i],
				taskId,
				true
			);
			postData.push(modifiedAnnotation);
		}
	}
	console.log('postData: ', postData);

	return postData;
};

const updateModifiedObjects = currentFeature => {
	// If the feature has an id then it is a modified object
	// Update the modifiedObjects array with the new feature
	if (currentFeature?.values_?.id !== undefined) {
		const currentSquareName = activeSquareRef?.properties?.tile_name;

		if (!modifiedObjects[currentSquareName]) {
			modifiedObjects[currentSquareName] = [];
		}

		const currentSquareObjects = modifiedObjects[currentSquareName];
		const objectIndex = currentSquareObjects.findIndex(
			object => object.values_.id === currentFeature.values_.id
		);

		if (objectIndex !== -1) {
			currentSquareObjects[objectIndex] = currentFeature;
			console.log('replaced in modifiedObjects');
			return;
		}

		currentSquareObjects.push(currentFeature);
		console.log('added to modifiedObjects');
	}
};
