import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import Loader from "@components/common/Loader";
import StripeProducts from "@components/products/StripeProducts";
import ProductsFooter from "@components/products/ProductsFooter";
import DemoProjects from "@components/demoProjects/DemoProjects";

import { UserContext } from "@contexts/User.context";


export const Wrapper = styled.div`
	color: ${(props) => props.theme.colors.textColor};
	padding-top: 1.5rem;
	padding-bottom: 3rem;
`;

const StyledBgContainer = styled(Container)`
	background-color: #000;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	margin-top: 2.5rem;

	h2{
		font-size: 1.4rem;
		font-weight: 300;
		margin-top: 1rem;
	}
	p{
		margin-bottom: 2rem;
		color: ${props => props.theme.colors.textAccent};
	}
`;

const Header = styled.h1`
	span {
		color: ${props => props.theme.colors.green};
	}
`;

const SubHeader = styled.p`
	color: ${props => props.theme.colors.textAccent};
	max-width: 620px;
	width: 100%;
	&.lead{
		font-size: 1.4rem;

	}
`;

export default function Home() {
	return (
		<Wrapper>
			<HomeView />
		</Wrapper>
	);
}

const HomeView = () => {
	const {
		currentUser,
		loading,
		error,
		tierPro,
		subscriptionLoading,
		subscriptionActive
	} = useContext(UserContext);


	if(loading || (currentUser.subscription_id && subscriptionLoading)){
		return <Container><Loader /></Container>;
	}

	if (!currentUser.loggedIn) {
		return (
			<Container>
				<HeroSection />

				<div className="d-flex gap-2">
					<Button variant="success" as={Link} to="/register">Get Access</Button>
					<Button variant="link" className="text-muted" as={Link} to="/login">Log in</Button>
				</div>
			</Container>
		)
	}

	if(!loading && error){
		return (
			<Container>
				<div className="alert alert-danger">
					<p className="mb-0">Error loading user data. Try again later.</p>
				</div>
			</Container>
		);
	}

	return (

		<>
			<Container>
				<HeroSection />
			</Container>

			{tierPro &&
				(!subscriptionActive) ?
					(<>
						<Container>
							<p>
								You need an active subscription to use the Biodrone Portal. <br/>
								Please select and purchase one option below.
							</p>
							<StripeProducts />
							<ProductsFooter />
						</Container>

						<DemoProjects wrapper={({children}) => {
							return (
								<StyledBgContainer fluid>
									<Container>
										<h2>Curious about what's possible?</h2>
										<p>Explore our demo projects showcasing the outcomes of AI models.</p>
										{children}
									</Container>
								</StyledBgContainer>
							);
						}} />
					</>)
				:
					(<Container><Button variant="success" as={Link} to="/projects">Your Projects</Button></Container>)
			}

		</>
	);
};

const HeroSection = () => {
	return (
		<div>
			<Header className="my-4 display-5">
				<span>Welcome to Biodrone</span>
				<br />
				Your Portal to AI Powered
				<br />
				Object Detection Tools
			</Header>

			<SubHeader className="lead">
				Identify objects, track alterations, and uncover patterns.
			</SubHeader>
			<SubHeader className="mb-5">
				A cloud-based software solution for analysing drone images of nature habitats with the help of artificial intelligence.
			</SubHeader>
		</div>
	);
};

