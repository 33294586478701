// Third-party library imports
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Button } from 'react-bootstrap';
import { VscRecord, VscChromeMaximize } from 'react-icons/vsc';
import { BiShapePolygon, BiShapeTriangle } from 'react-icons/bi';
import { BsFillEraserFill } from 'react-icons/bs';

// Contexts
import { useProject } from '@contexts/Project.context';

// Hooks
import useKeyboardShortcut from '@hooks/useKeyboardShortcut.hook';

// Utils
import { KeyboardShortcut } from '@components/map/sharedStyles.component';

// Components
import UtilityButton from './UtilityButton';

const iconStyles = { marginTop: '-4px', marginRight: '5px' };

const iconMap = {
	Box: <VscChromeMaximize style={iconStyles} />,
	Circle: <VscRecord style={iconStyles} />,
	Freehand: <BiShapePolygon style={iconStyles} />,
	Polygon: <BiShapeTriangle style={iconStyles} />,
};

const DrawingIcon = ({ toolType }) => {
	return iconMap[toolType] || null;
};

const AnnotationDrawTools = ({ startDraw, startErase, drawing, disabled }) => {
	const {
		modelType,
		annotationMode,
		annotationDrawTool,
		availableAnnotationDrawTools,
		dispatch,
	} = useProject();

	const drawToolOptions = availableAnnotationDrawTools[modelType]?.map(
		tool => {
			return {
				label: tool,
				value: tool,
				active: tool === annotationDrawTool,
				icon: () => <DrawingIcon toolType={tool} />,
			};
		}
	);

	const changeDrawTool = tool => {
		console.log('Changing draw tool to', tool.value);
		dispatch({ type: 'setAnnotationDrawTool', payload: tool.value });

		if (!drawing) {
			startDraw();
		}
	};

	useKeyboardShortcut('D', !!annotationMode && !disabled, () => startDraw());
	useKeyboardShortcut('E', !!annotationMode && !disabled, () => startErase());

	return (
		<div>
			<ButtonGroup className="me-3">
				{drawToolOptions.map((tool, index) => (
					<UtilityButton
						key={`tool-${index}`}
						label={tool.label}
						tooltip={{
							id: 'annotate-tip',
							content: `${tool.label} tool`,
							place: 'top',
						}}
						onClick={() => changeDrawTool(tool)}
						variant={tool.active && drawing ? 'success' : 'dark'}
						icon={tool.icon}
						disabled={disabled}
					/>
				))}
				<Button
					onClick={startDraw}
					variant="dark"
					className="p-1 pe-2"
					data-tooltip-id="annotate-tip"
					data-tooltip-content="Keyboard shortcut 'D' to draw"
					data-tooltip-place="top"
					disabled={disabled}>
					<KeyboardShortcut>D</KeyboardShortcut>
				</Button>
			</ButtonGroup>

			<UtilityButton
				label="Erase"
				tooltip={{
					id: 'annotate-tip',
					content: 'Erase annotations from the current square',
					place: 'top',
				}}
				onClick={startErase}
				variant={!drawing ? 'success' : 'dark'}
				keyboardShortcutLabel="E"
				icon={() => <BsFillEraserFill style={iconStyles} />}
				disabled={disabled}
			/>
		</div>
	);
};
export default AnnotationDrawTools;
