import { useMemo } from 'react';
import styled from 'styled-components';

import { FiHelpCircle } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { Dropdown } from 'react-bootstrap';
import { useProject } from '@contexts/Project.context';

const DropdownHeader = styled(Dropdown.Header)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;
	width: 100%;
	padding-left: 35px;
	span {
		display: inline-block;
		line-height: 1;
	}
`;
const DropDownButton = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	font-style: italic;
	&:hover {
		background: none;
	}
`;

const Row = styled.i`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const DeleteIcon = styled(MdDelete)`
	transform: scale(1.2);
	transition: 0.2s;
	button:not(:disabled) & {
		&:hover {
			cursor: pointer;
			color: ${props => props.theme.colors.warning};
		}
	}
`;

const NoResults = styled.div`
	padding: 10px 35px;
	font-size: 0.875rem;
`;

const TooltipSpan = ({ content, children }) => (
	<span
		data-tooltip-id="tooltip-toolbar-root"
		data-tooltip-content={content}
		data-tooltip-place="left">
		{children}
	</span>
);

const ModelList = ({
	models,
	taskDescription,
	pickTask,
	searchValue,
	trashTask,
	disabled,
}) => {
	const { pickedTask } = useProject();

	const baseModels = useMemo(
		() => models?.filter(task => task.basemodel),
		[models]
	);
	const customModels = useMemo(
		() => models?.filter(task => !task.basemodel),
		[models]
	);

	return (
		<>
			{baseModels && (
				<>
					<Header
						title="Base Models"
						content="Base models are pre-generated by Biodrone and cannot be modified or removed"
					/>

					{baseModels.map(task => (
						<BaseModelItem
							key={task.model_uuid}
							task={task}
							pickedTask={pickedTask}
							pickTask={pickTask}
							taskDescription={taskDescription}
							searchValue={searchValue}
							disabled={disabled}
						/>
					))}

					{baseModels.length === 0 && (
						<NoResults>No Base Models available</NoResults>
					)}

					<Dropdown.Divider />
				</>
			)}

			{customModels && (
				<>
					<Header
						title="Customizable Models"
						content="Customizable models can be trained to detect any object of interest"
					/>

					{customModels.map(task => (
						<CustomModelItem
							key={task.model_uuid}
							task={task}
							pickedTask={pickedTask}
							pickTask={pickTask}
							trashTask={trashTask}
							taskDescription={taskDescription}
							searchValue={searchValue}
							disabled={disabled}
						/>
					))}

					{customModels.length === 0 && (
						<NoResults>No Custom Models exists</NoResults>
					)}
				</>
			)}
		</>
	);
};

export default ModelList;

const Header = ({ title, content }) => {
	return (
		<DropdownHeader>
			<span>{title}</span>
			<TooltipSpan content={content}>
				<FiHelpCircle />
			</TooltipSpan>
		</DropdownHeader>
	);
};

const BaseModelItem = ({
	task,
	pickedTask,
	pickTask,
	taskDescription,
	searchValue,
	disabled,
}) => {
	return (
		<Dropdown.Item
			as="button"
			type="button"
			id={task.model_uuid}
			key={task.model_uuid}
			onClick={() => pickTask(task)}
			disabled={
				(pickedTask && pickedTask.model_uuid === task.model_uuid) ||
				disabled
			}
			className={
				task.description
					.toLowerCase()
					.includes(searchValue.toLowerCase())
					? ''
					: 'd-none'
			}>
			<Row>
				<span>{taskDescription(task)}</span>
			</Row>
		</Dropdown.Item>
	);
};

const CustomModelItem = ({
	task,
	pickedTask,
	pickTask,
	trashTask,
	taskDescription,
	searchValue,
	disabled,
}) => {
	return (
		<Dropdown.Item
			as="div"
			id={task.model_uuid}
			className={
				task.description
					.toLowerCase()
					.includes(searchValue.toLowerCase())
					? ''
					: 'd-none'
			}>
			<Row>
				<DropDownButton
					type="button"
					className="dropdown-item"
					onClick={() => pickTask(task)}
					disabled={
						(pickedTask &&
							pickedTask.model_uuid === task.model_uuid) ||
						disabled
					}>
					{taskDescription(task)}
				</DropDownButton>

				<DropDownButton
					type="button"
					onClick={() => trashTask(task)}
					disabled={
						pickedTask && pickedTask.model_uuid === task.model_uuid
					}>
					<DeleteIcon className="ms-2" />
				</DropDownButton>
			</Row>
		</Dropdown.Item>
	);
};
