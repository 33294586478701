import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Alert from '@components/alert/Alert';
import SubscriptionsAvailableForUser from '@components/subscription/SubscriptionsAvailableForUser';

const Container = styled.div`
	width: 100%;
	max-width: 700px;
	margin-bottom: 3rem;
`;

export const ProjectLimitWarning = ({ monthlyProjectsLimit }) => {
	const { limit, days_left: daysLeft } = monthlyProjectsLimit;

	return (
		<>
			<Container>
				<Alert
					variant="warning"
					heading={`Limit of ${limit} projects reached`}>
					<p>
						Your subscription is limited to creating{' '}
						<strong>{limit} projects</strong> each billing period.{' '}
						<br />
						Please note that deleted projects are also counted
						towards this limit.
						<br />
						<Link to="/subscription" className="inherit">
							See details of your usage in the subscription
							overview.
						</Link>
					</p>

					<p>
						Please wait{' '}
						<strong>
							{daysLeft} {daysLeft > 1 ? 'days' : 'day'}
						</strong>{' '}
						for your subscription to renew, or choose a subscription
						with a higher limit from the options below.
					</p>
				</Alert>
			</Container>

			<div>
				<h2 className="h1 mb-4">Available Subscriptions</h2>
				<SubscriptionsAvailableForUser
					filterProductsBy={{ monthly_project_limit: limit }}
				/>
			</div>
		</>
	);
};
