import { api } from './index.js';

export const getTasks = async project_uuid => {
	const { data } = await api.get(`/tasks/tasks_with_model/${project_uuid}`);
	return data;
};

export const createTask = async ({
	project_uuid,
	classes,
	description,
	isSegmentation,
	image_mode,
}) => {
	const postData = {
		project_uuid,
		classes,
		description,
		segmentation: isSegmentation,
		image_mode,
	};

	const { data } = await api.post(`/tasks/${project_uuid}`, postData);
	return data;
};

export const deleteTask = async task => {
	const { data } = await api.delete(
		`/tasks/delete-task/${task.project_uuid}/${task.task_uuid}`
	);
	return data;
};

export const deleteModel = async model_uuid => {
	const { data } = await api.delete(`/models/${model_uuid}`);
	return data;
};

export const deleteMultiModel = async model => {
	const { data } = await api.delete(`/models/multimodel/${model.model_uuid}`);
	return data;
};
