import { startOfMonth, endOfDay, subMonths, endOfMonth } from 'date-fns';

/**
 * Checks if the password is valid
 * @param {string} password The password to check
 * @param {string} confirmPassword The password to check
 * @returns {string|boolean} Returns true if the password is valid, otherwise it returns a string with the error message
 */
export const checkPassword = (password, confirmPassword = null) => {
	const errors = [];

	if (confirmPassword) {
		if (password !== confirmPassword) {
			errors.push('Passwords do not match');
		}
	}
	if (password.length < 8) {
		errors.push('Password must be at least 8 characters long');
	}
	// make sure the password contains at least one uppercase letter
	if (!password.match(/[A-Z]/)) {
		errors.push('Password must contain at least one uppercase letter');
	}
	// make sure the password contains at least one lowercase letter
	if (!password.match(/[a-z]/)) {
		errors.push('Password must contain at least one lowercase letter');
	}
	// make sure the password contains at least one number
	if (!password.match(/[0-9]/)) {
		errors.push('Password must contain at least one number');
	}
	// make sure the password contains at least one special character including !@#$%^&*()_+*><
	if (!password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
		errors.push('Password must contain at least one special character');
	}

	if (errors.length > 0) {
		return { validated: false, errors };
	}

	return {
		validated: true,
	};
};

/**
 * returns the job name based on the job type
 * @param {string} type The job type
 * @returns {string} Returns the job name
 */
export const getJobName = type => {
	switch (type) {
		case 'segmentation_train':
			return 'segmentation training';
		case 'segmentation_predict':
			return 'segmentation prediction';
		case 'object_detection_train':
			return 'object detection training';
		case 'object_detection_predict':
			return 'object detection prediction';
		case 'grid_tiling':
			return 'Grid tiling';
		case 'reproject_tif':
			return 'reproject TIF';
		case 'user_annotation_processing':
			return 'user annotation processing';
		case 'user_annotation_train':
			return 'user annotation training';

		default:
			return 'job in progress';
	}
};

export const cleanUserInput = text => {
	return text
		.replace(/[^a-zA-Z0-9 ]/g, '')
		.trim()
		.replace(/  +/g, ' ')
		.toLowerCase()
		.replace(/\b[a-z]/g, letter => letter.toUpperCase());
};

export const billingDateRange = () => {
	const currentDate = new Date();
	const lastMonthFirstDay = startOfMonth(subMonths(currentDate, 1));
	const lastMonthLastDay = endOfDay(endOfMonth(subMonths(currentDate, 1)));
	const dateRange = {
		from: lastMonthFirstDay,
		to: lastMonthLastDay,
	};

	return JSON.stringify(dateRange);
};

// Format number with commas as thousands separators
export const formatAreaNumber = (n, decimalPlaces = 2) => {
	if (!isNaN(parseFloat(n))) {
		// Round the number to the specified decimal places
		const roundedNumber = Number(Number(n).toFixed(decimalPlaces));

		// Use toLocaleString() to add commas as thousands separators
		return roundedNumber.toLocaleString();
	}
	return n;
};

/**
 * Converts area to specified unit
 * @param {*} area Area in square meters
 * @param {*} unit Desired unit (da or ha)
 * @returns
 */
export const convertArea = (area, unit = 'ha') => {
	// If are is not a number, return undefined
	if (area === null || isNaN(area)) {
		console.error('Invalid area. Please provide a valid number.');
		return undefined;
	}

	let result = 0;
	if (unit === 'da') {
		// 1 decares = 100 square meters
		result = area / 100;
	} else if (unit === 'ha') {
		// 1 hectare = 10,000 square meters
		result = area / 10000;
	} else {
		console.error(
			"Invalid unit. Please use 'da' for decares or 'ha' for hectares."
		);
		return undefined;
	}

	return parseFloat(result.toFixed(2));
};

export const recurringIntervalSwitch = interval => {
	switch (interval) {
		case 'day':
			return 'daily';
		case 'week':
			return 'weekly';
		case 'month':
			return 'monthly';
		case 'year':
			return 'yearly';
		default:
			return null;
	}
};
