import Button from 'react-bootstrap/Button';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { useAuth } from '@contexts/User.context';

const NewModel = ({ addCustomTask, customModels }) => {
	const { tierTrial, trialEnded, currentUser } = useAuth();

	const handleAddCustomTask = () => {
		if (tierTrial) {
			if (trialEnded) {
				alert(
					'Your trial has ended and you cannot create any more custom models'
				);
				return;
			}

			//if custom models has more than one user created model, then alert
			const userCreatedModels = customModels.filter(
				model => model.creator_id === currentUser.uuid
			);
			if (userCreatedModels.length > 0) {
				alert(
					'You can only create one customizable model as a trial user'
				);
				return;
			}
		}

		addCustomTask();
	};

	return (
		<>
			<Button
				variant="dark"
				className="border-0 w-100 pt-3 pb-2 ps-2 pe-2"
				onClick={handleAddCustomTask}
				data-tooltip-id="tooltip-toolbar-root"
				data-tooltip-content={
					tierTrial && trialEnded
						? 'Your trial has ended'
						: tierTrial
						? 'You can create one customizable model as a trial user'
						: 'Create a model to detect new objects'
				}
				data-tooltip-place="right">
				<IoMdAddCircleOutline
					style={{ transform: 'scale(1.2)' }}
					className="m-1 mb-2"
				/>
				Create Custom AI Model
			</Button>
		</>
	);
};

export default NewModel;
