import { useAuth } from '@contexts/User.context';
import { useQuery } from '@tanstack/react-query';
import { getBillingPortalUrl } from '@utils/api';

const BillingPortal = () => {
	const { currentUser } = useAuth();

	// Create Stripe billing portal URL
	const { data: stripeBillingPortalUrl, isError } = useQuery({
		queryKey: ['billingPortalUrl', currentUser?.uuid],
		queryFn: () =>
			getBillingPortalUrl({ show_default_subscriptions: true }),
		enabled: !!currentUser?.uuid,
	});

	const billingPortalUrl = stripeBillingPortalUrl?.portal_url ?? null;

	if (isError) {
		return (
			<Alert variant="danger" className="w-50 mt-4">
				Could not load subscription management. <br />
				Please contact{' '}
				<a href="mailto:post@biodrone.no">post@biodrone.no</a> if the
				problem persists.
			</Alert>
		);
	}

	return (
		<>
			{billingPortalUrl && (
				<p className="mt-4 mb-0">
					<a href={billingPortalUrl} rel="noreferrer">
						Manage your current subscription in the Stripe billing
						portal.
					</a>
				</p>
			)}
		</>
	);
};

export default BillingPortal;
