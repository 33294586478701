import { debounce } from 'lodash';
import styled from 'styled-components';

import { Range } from 'react-range';
import { Tooltip } from 'react-tooltip';

const Track = styled.div`
	height: 6px;
	background-color: #8c8b8b;
`;

const Thumb = styled.div`
	height: 16px;
	width: 16px;
	border-radius: 50%;
	box-shadow: 0px 2px 6px #1c1c1c;
	background-color: ${props => props.theme.colors.green};
`;

const IndexSlider = ({
	min = 0,
	max = 1,
	sliderRef,
	slideIndex,
	setSlideIndex,
}) => {
	if (min === max) {
		return <div></div>;
	}

	const handleIndexSliderChange = debounce(index => {
		setSlideIndex(index);
		sliderRef.current.slickGoTo(index);
	}, 50);

	return (
		<>
			<Tooltip id="index-slider" variant="dark" zindex={50} />

			<Range
				onChange={handleIndexSliderChange}
				min={min}
				max={max}
				step={1}
				values={slideIndex}
				renderTrack={({ props, children }) => (
					<Track
						{...props}
						style={{
							...props.style,
						}}>
						{children}
					</Track>
				)}
				renderThumb={({ props }) => (
					<Thumb
						{...props}
						style={{
							...props.style,
						}}
						data-tooltip-id="index-slider"
						data-tooltip-content={slideIndex}
						data-tooltip-place="top"
					/>
				)}
			/>
		</>
	);
};
export default IndexSlider;
